import { QuestionType } from "../types";
import { verifyAnswer } from "../utils/verify-answer";

export const Question = ({
    id: questionId, label, answers,  setResult 
   }: QuestionType & { setResult: Function }) => {
   console.log('Question-render');
 
   return (
  
     <div className="question"> 
       <div className="label">{ label }</div>
       <div className="answers">
         { answers.map(({ label, id, text }) => <div>
           <div key={ `answer-${ id }` } onClick={ 
             () => verifyAnswer(id, questionId, setResult) 
           }>          
             { label } {text}
             </div>
         </div>) } 
       </div>
       <h1>Form</h1>
      <form className="form">
        {answers.map(({ label, id, text }) => <div>
          <input type="radio" key={`answer-${ id }`} id="a" name="A" value="bike"
          onClick={() => console.log("Answer clicked")}
          ></input>
          <label>{`(${label}) `} {text}</label>
          </div>
        )}
        <input type="submit" value="submit" 
         />
      </form>
     </div>
     
   )
 }