import { QuestionType } from "./types";

export const questions: QuestionType[] = [
    { 
      label: 'whatever?',
      id: 1,
      answers: [
        {
          id: 1,
          label: 'A',
          text: 'Is that true?'
        },
        { 
          id: 2,
          label: 'B',
          correct: true,
          text: 'Is that true?'
        },
        {
          id: 3,
          label: 'C',
          text: 'Is that true?'
        },
        { 
          id: 4,
          label: 'D',
          text: 'Is that true?'
        },
       
      ]
    },
    { 
      label: 'whatever2?',
      id: 2,
      answers: [
        { 
          id: 1,
          label: 'A',
          correct: true,
          text: 'Is that true?'
        },
        { 
          id: 2,
          label: 'B',
          text: 'Is that true?'
        },
        { 
          id: 3,
          label: 'C',
          text: 'Is that true?'
        },
        { 
          id: 4,
          label: 'D',
          text: 'Is that true?'
        }
      ]
    }
  ];